import { AbstractCase } from "../types/AbstractCase";
import * as caseStatus from "./LeadStatus/StatusCase";
import { isOpponentLawyerDataComplete } from "../components/Case/CasePdfGenerator/casePdfGeneratorService";
import * as divorceStatus from "./LeadStatus/StatusDivorce";
import * as settlementStatus from "./LeadStatus/StatusSettlement";
import * as trafficStatus from "./LeadStatus/StatusTraffic";
import { CourtAppointment } from "../types/CourtAppointment";
import * as documentStatus from "./LeadStatus/StatusDocument";
import GeneratableFile from "../types/GeneratableFile";

const generatableContractFilesBase: GeneratableFile[] = [
  {
    value: "powerOfAttorney",
    label: "Vollmacht",
    isDisabled: (product) => {
      return product.leadStatus < caseStatus.STATUS_DATA_COMPLETED;
    },
  },
  {
    value: "powerOfAttorneyWithoutSignature",
    label: "Leere Vollmacht",
    isDisabled: (product) => {
      return product.leadStatus < caseStatus.STATUS_DATA_COMPLETED;
    },
  },
  {
    value: "claimLetter",
    label: "Außergerichtliches Schreiben",
    isDisabled: (product) => {
      return product.leadStatus < caseStatus.STATUS_DATA_COMPLETED;
    },
  },
  {
    value: "statementOfWithdrawal",
    label: "Rücktritt - vorherige Nachbesserungsaufforderung",
    isHidden: (product) => {
      return !(
        (product.productClassName === "vehicleContract" || product.productClassName === "purchaseContract") &&
        (product.objectiveLawFirm === "fulfillment" || product.objectiveLawFirm === "replacement")
      );
    },
    isDisabled: (product) => {
      return product.leadStatus < caseStatus.STATUS_EXTRAJUDICIAL_COMMUNICATION_SENT;
    },
  },
  {
    value: "debtCollectionLetter",
    label: "Außergerichtliches Schreiben Inkasso",
    isDisabled: (product) => {
      return product.leadStatus < caseStatus.STATUS_CHECKED || !product.debtCollectionAgency;
    },
  },
  {
    value: "advocardFlexCoverageRequest",
    label: "FlexService Deckungsanfrage",
    isDisabled: (product) => {
      return (
        product.leadStatus < caseStatus.STATUS_DATA_COMPLETED || product.insurance.specialTermsTag !== "wasAdvocardFlex"
      );
    },
  },
  {
    value: "coverLetterJudicialCoverageRequest",
    label: "Deckungsanfrage Gerichtsverfahren",
    isDisabled: (product) => product.leadStatus < caseStatus.STATUS_PREPARE_LAWSUIT,
  },
  {
    value: "statementOfDefenseWithApplication",
    label: "Verteidigungsanzeige mit Antrag",
    isDisabled: (product) => {
      return product.leadStatus < caseStatus.STATUS_LAWSUIT || !product.statementOfClaimFiledDate;
    },
  },
  {
    value: "statementOfDefenseWithApplicationNoDeadlineExtension",
    label: "Verteidigungsanzeige mit Antrag (ohne Fristverlängerung)",
    isDisabled: (product) => {
      return product.leadStatus < caseStatus.STATUS_LAWSUIT || !product.statementOfClaimFiledDate;
    },
  },
  {
    value: "statementOfDefense",
    label: "Verteidigungsanzeige ohne Antrag",
    isDisabled: (product) => {
      return product.leadStatus < caseStatus.STATUS_LAWSUIT || !product.statementOfClaimFiledDate;
    },
  },
  {
    value: "statementOfDefenseNoDeadlineExtension",
    label: "Verteidigungsanzeige ohne Antrag (ohne Fristverlängerung)",
    isDisabled: (product) => {
      return product.leadStatus < caseStatus.STATUS_LAWSUIT || !product.statementOfClaimFiledDate;
    },
  },
  {
    value: "objectionToEnforcementOrder",
    label: "Einspruch gegen Vollstreckungsbescheid",
    isDisabled: (product) => {
      return product.leadStatus < caseStatus.STATUS_LAWSUIT || !product.enforcementOrderDate;
    },
  },
  {
    value: "declarationOfCompletion",
    label: "Erledigungserklärung",
    isDisabled: (product) => {
      return product.leadStatus < caseStatus.STATUS_LAWSUIT;
    },
  },
  {
    value: "abandonmentOfAction",
    label: "Klagerücknahme",
    isDisabled: (product) => {
      return product.leadStatus < caseStatus.STATUS_LAWSUIT;
    },
  },
  {
    value: "extrajudicialDeadlineExtension",
    label: "Außergerichtliche Fristverlängerung",
    isDisabled: (product) => {
      return product.leadStatus < caseStatus.STATUS_WAITING_FOR_FEEDBACK;
    },
  },
  {
    value: "assessmentOfCostsRequest",
    label: "Kostenfestsetzungsantrag",
    isDisabled: (product) => {
      return product.leadStatus < caseStatus.STATUS_LAWSUIT_COMPLETED;
    },
  },
  {
    value: "enforcementInstrument",
    label: "Zwangsvollstreckung: Versand Titel an Kunden",
    isDisabled: (product) => {
      return product.leadStatus < caseStatus.STATUS_DATA_COMPLETED;
    },
  },
  {
    value: "costCompensationRequest",
    label: "Kostenausgleichsantrag",
    isDisabled: (product) => {
      return product.leadStatus < caseStatus.STATUS_LAWSUIT_COMPLETED;
    },
  },
  {
    value: "formalNoticeCostsRequest",
    label: "Zahlungsaufforderung aus KFB",
    isDisabled: (product) => {
      return (
        product.leadStatus < caseStatus.STATUS_LAWSUIT_COMPLETED ||
        product.costBearingByOpponentFinalAbsolute === null ||
        product.courtCostsFinal === null ||
        isOpponentLawyerDataComplete(product)
      );
    },
  },
  {
    value: "formalNoticeCostsRequestLawyer",
    label: "Zahlungsaufforderung aus KFB an RA",
    isDisabled: (product) => {
      return (
        product.leadStatus < caseStatus.STATUS_LAWSUIT_COMPLETED ||
        product.costBearingByOpponentFinalAbsolute === null ||
        product.courtCostsFinal === null ||
        !isOpponentLawyerDataComplete(product)
      );
    },
  },
  {
    value: "formalPaymentNotice",
    label: "Zahlungsaufforderungsschreiben",
    isDisabled: (product) => {
      return product.leadStatus < caseStatus.STATUS_LAWSUIT_COMPLETED || !product.principalClaim;
    },
  },
  {
    value: "statusRequestCourt",
    label: "Sachstandsanfrage Gericht",
    isDisabled: (product) => {
      return product.leadStatus < caseStatus.STATUS_LAWSUIT;
    },
  },
  {
    value: "briefing",
    label: "Briefing TV Gerichtstermin",
    isDisabled: (product) => {
      return product.leadStatus !== caseStatus.STATUS_TRIAL || product.courtAppointments?.length < 1;
    },
  },
  {
    value: "notificationNewAddress",
    label: "Mitteilung neue Anschrift Mandant",
    isDisabled: (product) => {
      return product.leadStatus < caseStatus.STATUS_LAWSUIT;
    },
  },
  {
    value: "halfOfCourtFeeReimbursementRequest",
    label: "Antrag Gerichtskosten-Ausgleich",
    isDisabled: (product) => {
      return product.leadStatus < caseStatus.STATUS_LAWSUIT_COMPLETED;
    },
  },
  {
    value: "higherInstanceAppeal",
    label: "Berufungseinlegung",
    isDisabled: (product) =>
      !product.isHigherInstanceCase ||
      product.leadStatus < caseStatus.STATUS_PREPARE_LAWSUIT ||
      !product.insurance.judicialCoverageGranted ||
      !product.previousInstanceCase.promulgationOfJudgementDate ||
      !product.previousInstanceCase.judgementEebSentDate,
  },
  {
    value: "applicationHigherInstanceDeadlineExtension",
    label: "Berufung - Antrag Fristverlängerung",
    isDisabled: (product) =>
      !product.isHigherInstanceCase || product.leadStatus < caseStatus.STATUS_LAWSUIT || !product.judgementReceivedDate,
  },
  {
    value: "contractReminderLetter",
    label: "Erinnerungsschreiben",
    isDisabled: (product) => {
      return !(
        product.leadStatus === caseStatus.STATUS_EXTRAJUDICIAL_COMMUNICATION_SENT ||
        product.leadStatus === caseStatus.STATUS_EXTRAJUDICIAL_REACTION_RECEIVED
      );
    },
  },
];

const contractLawSpecificGeneratableFiles: GeneratableFile[] = [
  ...generatableContractFilesBase,
  {
    value: "accessToInvestigationFile",
    label: "Akteneinsicht Ermittlungsakte",
    isDisabled: (product) => {
      return product.leadStatus < caseStatus.STATUS_WAITING_FOR_FEEDBACK || !product.responsibleProsecution;
    },
  },
];

export const generatableFiles: {
  [key: string]: GeneratableFile[];
} = {
  divorce: [
    {
      value: "divorceApplicationComplete",
      label: "Scheidungsantrag",
      isDisabled: (divorce) => {
        return divorce.leadStatus < divorceStatus.STATUS_CHECKED;
      },
    },
    {
      value: "divorceApplication",
      label: "Scheidungsantrag (ohne VKH Anhänge)",
      isDisabled: (divorce) => {
        return divorce.hasProcessCostSupport === false || divorce.leadStatus < divorceStatus.STATUS_CHECKED;
      },
    },
    {
      value: "installmentOffer",
      label: "Ratenzahlungsangebot",
      isDisabled: (divorce) => {
        return divorce.leadStatus < divorceStatus.STATUS_DATA_COMPLETED;
      },
    },
    {
      value: "installmentPlan",
      label: "Ratenzahlungsplan",
      isDisabled: (divorce) => {
        return divorce.leadStatus < divorceStatus.STATUS_DATA_COMPLETED;
      },
    },
    {
      value: "legalEffect",
      label: "Scheidungsbeschluss mit Rechtskraftvermerk",
      isDisabled: (divorce) => {
        return divorce.leadStatus < divorceStatus.STATUS_DATE_SET;
      },
    },
    {
      value: "powerOfAttorney",
      label: "Vollmacht",
      isDisabled: (divorce) => {
        return divorce.leadStatus < divorceStatus.STATUS_POWER_OF_ATTORNEY_GRANTED;
      },
    },
    {
      value: "powerOfAttorneyWithoutSignature",
      label: "Leere Vollmacht",
      isDisabled: (divorce) => {
        return divorce.leadStatus < divorceStatus.STATUS_POWER_OF_ATTORNEY_GRANTED;
      },
    },
    {
      value: "briefing",
      label: "Briefing TV Scheidungstermin",
      isDisabled: (divorce) => {
        return divorce.leadStatus < divorceStatus.STATUS_DATE_SET;
      },
    },
    {
      value: "questionnairePensionAdjustment",
      label: "Übersendung Fragebogen Versorgungsausgleich",
      isDisabled: (divorce) => {
        return divorce.leadStatus < divorceStatus.STATUS_SUBMITTED;
      },
    },
    {
      value: "transmissionNotarisedAgreement",
      label: "Übersendung Notarvertrag",
      isDisabled: (divorce) => {
        return divorce.leadStatus < divorceStatus.STATUS_SUBMITTED;
      },
    },
    {
      value: "transmissionInquiredDocuments",
      label: "Übersendung geforderte Unterlagen",
      isDisabled: (divorce) => {
        return divorce.leadStatus < divorceStatus.STATUS_SUBMITTED;
      },
    },
    {
      value: "notificationMissingDocumentsPensionAdjustment",
      label: "Mitteilung fehlende Unterlagen Versorgungsausgleich",
      isDisabled: (divorce) => {
        return divorce.leadStatus < divorceStatus.STATUS_SUBMITTED;
      },
    },
    {
      value: "notificationNewAddress",
      label: "Mitteilung neue Anschrift Mandant",
      isDisabled: (divorce) => {
        return divorce.leadStatus < divorceStatus.STATUS_SUBMITTED;
      },
    },
    {
      value: "statusRequestCourt",
      label: "Sachstandsanfrage Gericht",
      isDisabled: (divorce) => {
        return divorce.leadStatus < divorceStatus.STATUS_SUBMITTED;
      },
    },
    {
      value: "requestDivorceDecision",
      label: "Anforderung Scheidungsbeschluss RKV",
      isDisabled: (divorce) => {
        return divorce.leadStatus < divorceStatus.STATUS_SUBMITTED;
      },
    },
  ],
  settlement: [
    {
      value: "dismissalProtectionSuitComplete",
      label: "Kündigungsschutzklage",
      isDisabled: (settlement) => {
        return settlement.leadStatus < settlementStatus.STATUS_CHECKED;
      },
    },
    {
      value: "powerOfAttorneySettlement",
      label: "Vollmacht",
      isDisabled: (settlement) => {
        return settlement.leadStatus < settlementStatus.STATUS_POWER_OF_ATTORNEY_GRANTED;
      },
    },
    {
      value: "powerOfAttorneySettlementWithoutSignature",
      label: "Leere Vollmacht",
      isDisabled: (settlement) => {
        return settlement.leadStatus < settlementStatus.STATUS_POWER_OF_ATTORNEY_GRANTED;
      },
    },
    {
      value: "briefing",
      label: "Briefing TV Gütetermin",
      isDisabled: (settlement) => {
        return settlement.leadStatus < settlementStatus.STATUS_DATE_SET_SETTLEMENT;
      },
    },
    {
      value: "transmissionInquiredDocuments",
      label: "Übersendung geforderte Unterlagen",
      isDisabled: (settlement) => {
        return settlement.leadStatus < settlementStatus.STATUS_SUBMITTED;
      },
    },
    {
      value: "cancellationAppointmentComparison",
      label: "Aufhebung Termin wegen Vergleich",
      isDisabled: (settlement) => {
        return settlement.leadStatus < settlementStatus.STATUS_DATE_SET_SETTLEMENT;
      },
    },
    {
      value: "transmissionEnforceableComparison",
      label: "Übersendung vollstreckbarer Vergleich",
      isDisabled: (settlement) => {
        return settlement.leadStatus < settlementStatus.STATUS_SUBMITTED;
      },
    },
    {
      value: "notificationNewAddress",
      label: "Mitteilung neue Anschrift Mandant",
      isDisabled: (settlement) => {
        return settlement.leadStatus < settlementStatus.STATUS_SUBMITTED;
      },
    },
  ],
  traffic: [
    {
      value: "returnOfRecordsPdf",
      label: "Rücksendung Akte",
      isDisabled: (traffic) => {
        return (
          traffic.leadStatus < trafficStatus.STATUS_POWER_OF_ATTORNEY_GRANTED ||
          !traffic.fineNoticeReference ||
          !traffic.responsibleAuthority
        );
      },
    },
    {
      value: "entitlementLetterPdf",
      label: "Bestellungsschreiben",
      isDisabled: (traffic) => {
        return traffic.leadStatus < trafficStatus.STATUS_POWER_OF_ATTORNEY_GRANTED || !traffic.responsibleAuthority;
      },
    },
    {
      value: "accessToRecordsMessage",
      label: "Einspruch/Akteneinsicht",
      isDisabled: (traffic) => {
        return traffic.leadStatus < trafficStatus.STATUS_CHECKED || !traffic.responsibleAuthority;
      },
    },
    {
      value: "powerOfAttorneyTraffic",
      label: "Vollmacht",
      isDisabled: (traffic) => {
        return traffic.leadStatus < trafficStatus.STATUS_POWER_OF_ATTORNEY_GRANTED;
      },
    },
    {
      value: "powerOfAttorneyTrafficWithoutSignature",
      label: "Leere Vollmacht",
      isDisabled: (traffic) => {
        return traffic.leadStatus < trafficStatus.STATUS_POWER_OF_ATTORNEY_GRANTED;
      },
    },
    {
      value: "briefing",
      label: "Briefing TV Hauptverhandlung",
      isDisabled: (traffic) => {
        return traffic.leadStatus < trafficStatus.STATUS_TRIAL_DATE_SET;
      },
    },
    {
      value: "transmissionInquiredDocumentsAuthority",
      label: "Übersendung geforderte Unterlagen Behörde",
      isDisabled: (traffic) => {
        return !traffic.responsibleAuthority;
      },
    },
    {
      value: "deadlineExtension",
      label: "Fristverlängerung Einspruchsbegründung Behörde",
      isDisabled: (traffic) => {
        return traffic.leadStatus < trafficStatus.STATUS_CHECKED;
      },
    },
    {
      value: "drivingBanAppealEmployee",
      label: "Einlassung Fahrverbot Angestellte",
      isDisabled: (traffic) => {
        return (
          traffic.leadStatus < trafficStatus.STATUS_ACCESS_TO_RECORDS ||
          traffic.leadStatus > trafficStatus.STATUS_TRIAL_PAID
        );
      },
    },
    {
      value: "drivingBanAppealSelfEmployed",
      label: "Einlassung Fahrverbot Selbstständigkeit ",
      isDisabled: (traffic) => {
        return (
          traffic.leadStatus < trafficStatus.STATUS_ACCESS_TO_RECORDS ||
          traffic.leadStatus > trafficStatus.STATUS_TRIAL_PAID
        );
      },
    },
    {
      value: "rawMeasurementsDataRequest",
      label: "Rohmessdatenanforderung",
      isDisabled: (traffic) => {
        return (
          traffic.leadStatus < trafficStatus.STATUS_ACCESS_TO_RECORDS ||
          traffic.leadStatus > trafficStatus.STATUS_TRIAL_PAID
        );
      },
    },
    {
      value: "transmissionInquiredDocumentsCourt",
      label: "Übersendung geforderte Unterlagen Gericht",
      isDisabled: (traffic) => {
        return traffic.leadStatus < trafficStatus.STATUS_TRIAL;
      },
    },
    {
      value: "releaseFromPersonalAttendanceCourt",
      label: "Entbindung persönliches Erscheinen",
      isDisabled: (traffic) => {
        return (
          traffic.leadStatus < trafficStatus.STATUS_TRIAL ||
          !traffic.courtAppointments?.some((appointment: CourtAppointment) => !!appointment.courtDate?.appointmentDate)
        );
      },
    },
    {
      value: "appealWithdrawalAuthority",
      label: "Rücknahme Einspruch Behörde",
      isDisabled: (traffic) => {
        return traffic.leadStatus < trafficStatus.STATUS_CHECKED || !traffic.responsibleAuthority;
      },
    },
    {
      value: "appealWithdrawalProsecution",
      label: "Rückname Einspruch Staatsanwaltschaft",
      isDisabled: (traffic) => {
        return (
          traffic.leadStatus < trafficStatus.STATUS_CHECKED ||
          !traffic.responsibleProsecution ||
          !traffic.prosecutionReference
        );
      },
    },
    {
      value: "appealWithdrawalCourt",
      label: "Rücknahme Einspruch Gericht",
      isDisabled: (traffic) => {
        return traffic.leadStatus < trafficStatus.STATUS_TRIAL;
      },
    },
    {
      value: "consentResolutionProcedure",
      label: "§ 72 OWiG Zustimmung",
      isDisabled: (traffic) => {
        return !(
          traffic.leadStatus === trafficStatus.STATUS_TRIAL ||
          traffic.leadStatus === trafficStatus.STATUS_TRIAL_DATE_SET
        );
      },
    },
    {
      value: "objectionToResolutionProcedure",
      label: "§ 72 OWiG Widerspruch",
      isDisabled: (traffic) => {
        return !(
          traffic.leadStatus === trafficStatus.STATUS_TRIAL ||
          traffic.leadStatus === trafficStatus.STATUS_TRIAL_DATE_SET
        );
      },
    },
    {
      value: "requestVideoAuthority",
      label: "Videoanfrage bei Behörde",
      isDisabled: (traffic) => {
        return traffic.leadStatus < trafficStatus.STATUS_ACCESS_TO_RECORDS;
      },
    },
    {
      value: "requestReferenceProsecution",
      label: "Anfrage Aktenzeichen Staatsanwaltschaft",
      isDisabled: (traffic) =>
        !traffic.responsibleProsecution || !traffic.responsibleAuthority || !traffic.fineNoticeReference,
    },
    {
      value: "notificationNewAddress",
      label: "Mitteilung neue Anschrift Mandant",
      isDisabled: (traffic) => {
        return traffic.leadStatus < trafficStatus.STATUS_TRIAL;
      },
    },
    {
      value: "legalAppeal",
      label: "Rechtsbeschwerde",
      isDisabled: (traffic) => {
        return traffic.leadStatus !== trafficStatus.STATUS_DECISION_MADE;
      },
    },
  ],
  alimony: [
    {
      value: "alimony",
      label: "Unterhaltsanalyse",
      isDisabled: (alimony) => {
        return alimony.leadStatus < documentStatus.STATUS_COMPLETED;
      },
    },
  ],
  death: [
    {
      value: "deathAndInheritance",
      label: "Tod und Erbe",
      isDisabled: (death) => {
        return death.leadStatus < documentStatus.STATUS_COMPLETED;
      },
    },
  ],
  patientDecree: [
    {
      value: "patientDecree",
      label: "Patientenverfügung",
      isDisabled: (patientDecree) => {
        return patientDecree.leadStatus < documentStatus.STATUS_COMPLETED;
      },
    },
  ],
  powerOfAttorney: [
    {
      value: "powerOfAttorney",
      label: "Vorsorgevollmacht",
      isDisabled: (powerOfAttorney) => {
        return powerOfAttorney.leadStatus < documentStatus.STATUS_COMPLETED;
      },
    },
  ],
  dataLeakContract: [
    ...generatableContractFilesBase,
    {
      value: "dataLeakBriefing",
      label: "Zusatzbriefing TV Datenweitergabe",
      isDisabled: (product) => {
        return product.leadStatus !== caseStatus.STATUS_TRIAL || product.courtAppointments?.length < 1;
      },
    },
    {
      value: "dataLeakClientBriefing",
      label: "Zusatzbriefing Mandant Datenweitergabe",
      isDisabled: (product) => {
        return product.leadStatus !== caseStatus.STATUS_TRIAL || product.courtAppointments?.length < 1;
      },
    },
    {
      value: "schufaDataCopyRequest",
      label: "Forderungsschreiben",
      isDisabled: (product) =>
        product.leadStatus < caseStatus.STATUS_WAITING_FOR_FEEDBACK || product.leadStatus > caseStatus.STATUS_CHECKED,
    },
    {
      value: "coverLetterExtrajudicialCoverageRequest",
      label: "Deckungsanfrage außergerichtlich",
      isDisabled: (product) => product.leadStatus < caseStatus.STATUS_CHECKED,
    },
    {
      value: "personalAffectionLawFirm",
      label: "Schriftsatz persönliche Betroffenheit",
      isDisabled: (product) =>
        product.leadStatus > caseStatus.STATUS_TRIAL || product.leadStatus < caseStatus.STATUS_CHECKED,
    },
    {
      value: "personalAffectionLawFirmCorrection",
      label: "Korrektur persönliche Betroffenheit",
      isDisabled: (product) =>
        product.leadStatus > caseStatus.STATUS_TRIAL || product.leadStatus < caseStatus.STATUS_CHECKED,
    },
    {
      value: "dataLeakSuspensionRequest",
      label: "Antrag Aussetzung",
      isDisabled: (product) => product.leadStatus < caseStatus.STATUS_PREPARE_LAWSUIT,
    },
    {
      value: "dataLeakCoverageRequestVocation",
      label: "Deckungsanfrage Berufung",
      isDisabled: (product) => product.leadStatus < caseStatus.STATUS_TRIAL,
    },
    {
      value: "dataLeakStatementFederalSupremeCourt",
      label: "Stellungnahme BGH",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_LAWSUIT;
      },
    },
  ],
  eventContract: contractLawSpecificGeneratableFiles,
  membershipContract: contractLawSpecificGeneratableFiles,
  otherContract: contractLawSpecificGeneratableFiles,
  providerContract: contractLawSpecificGeneratableFiles,
  purchaseContract: contractLawSpecificGeneratableFiles,
  serviceContract: contractLawSpecificGeneratableFiles,
  subscriptionContract: contractLawSpecificGeneratableFiles,
  travelContract: contractLawSpecificGeneratableFiles,
  vehicleContract: contractLawSpecificGeneratableFiles,
  rentalContract: generatableContractFilesBase,
};

export const generatableFilesAndAttachments: { [key: string]: GeneratableFile[] } = {
  dataLeakContract: [
    {
      value: "dataLeakReplica",
      label: "Replik Datenweitergabe",
      isDisabled: (product: AbstractCase) =>
        product.courtReference === null || product.statementOfDefenseFiledDate === null,
    },
    {
      value: "statementOfClaim",
      label: "Klageschrift",
      isDisabled: (product) => {
        //this is inconsistent atm since statementOfClaim is a generic template in the backend. as soon as this is cleaned up
        //the data leak specific part should be removed. (its only needed as long as we have old cases without a personal affection anyways)
        return (
          product.leadStatus < caseStatus.STATUS_PREPARE_LAWSUIT || !product.personalAffection.personalAffectionLawFirm
        );
      },
    },
  ],
};
